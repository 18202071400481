<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import {dateUtil} from "@/helpers/date-util";
import {currencyHelper} from "@/helpers/currency-helper";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";

import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Admin MarketPlace Products Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    },

    currencyHelper() {
      return currencyHelper
    },

    dateUtil() {
      return dateUtil
    }
  },

  components: {
    CustomCardSubtitle,

    Layout,
    PageHeader
  },

  data() {
    return {

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,

        items: []
      },

      modals: {
        createProduct: {
          visible: false,
          product: {
            name: "",
            description: "",
            imageUrl: "",
            type: ""
          }
        }
      },

      imageFile: null,
      imageExtension: "",

      productTypes: null
    };
  },

  validations: {
    modals: {
      createProduct: {
        product: {
          name: {required},
          description: {required},
          type: {required}
        }
      }
    },

    imageFile: {required}
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.marketplace.products.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "image", slot: true, label: 'Miniaturka'},
        {key: "name", label: 'Nazwa produktu'},
        {
          key: "type", label: 'Typ produktu', formatter: value => {
            return value.replaceAll("_", " ").toLowerCase()
          }
        },
        {key: "status", slot: true, label: 'Status'},
        {key: "action", slot: true, label: 'Akcje'}
      ]
    },

    async loadProducts() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const result = await axios.get(`/marketplace/product/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.table.perPage
        }
      });

      this.table.items = result.data.marketplaceProducts;
      this.table.totalRows = result.data.count;
      this.table.rows = result.data.count;

      if (this.mounted) {
        this.$refs.table.refresh()
      }

      return this.table.items;
    },

    async loadProductTypes() {
      try {
        const result = await axios.get(`/marketplace/product/types`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.productTypes = result.data
      } catch (error) {
        console.log(error)
      }
    },

    changeRemovedStatus(item) {
      const duplicateObj = Object.assign({}, item)
      duplicateObj.removed = !duplicateObj.removed
      const json = JSON.stringify(duplicateObj)

      axios.put(`/marketplace/product`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        item.removed = duplicateObj.removed
        Swal.fire("Sukces!", item.removed ? 'Pomyślnie usunięto produkt' : 'Pomyślnie przywrócono produkt', "success");
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    openModal() {
      this.modals.createProduct.visible = true
    },

    hideModal() {
      this.modals.createProduct.visible = false
      this.modals.createProduct.product = {
        name: "",
        description: "",
        imageUrl: "",
        type: ""
      }
    },

    async createProduct() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.imageFile) {
        await this.upload()
      }

      axios.put(`/marketplace/product`, this.modals.createProduct.product, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        this.hideModal()
        Swal.fire("Sukces!", "Pomyślnie utworzono produkt", "success");

        this.table.items.push(result.data)
        this.table.rows += 1;
        this.table.totalRows += 1;

        this.$refs.table.refresh()
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    async upload() {
      const formData = new FormData();
      formData.append('file', this.imageFile);
      formData.append('extension', this.imageExtension)

      try {
        const result = await axios.post(`/cdn/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.modals.createProduct.product.imageUrl = result.data.url
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    uploadImage(input) {
      const image = input.target.files[0];
      this.imageFile = image;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = event => {
        const dataUrl = event.target.result;
        this.imageExtension = dataUrl.split(';')[0].split('/')[1];
      };
    }

  },

  async created() {
    await this.loadProductTypes()
    await this.loadProducts()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('admin.marketplace.products.title')" :items="getItems()"/>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <b-button variant="primary" @click="openModal">Utwórz produkt</b-button>
              </div>
            </div>
            <template v-if="table.items">
              <ecat-table
                  :table="table"
                  :fields="getFields()"
                  :items="loadProducts"
                  :pagination-top="true"

              >
                <template v-slot:image="{ item }">
                  <img :src="item.imageUrl" alt="default image" class="rounded avatar-lg">
                </template>

                <template v-slot:status="{ item }">
                    <span class="badge"
                          :class="item.removed ? 'badge-soft-danger' : 'badge-soft-success'">{{
                        item.removed ? 'Usunięty' : 'Aktywny'
                      }}</span>
                </template>

                <template v-slot:action="{ item }">
                  <b-button variant="primary" :href="`/dashboard/admin/marketplace/product/${item.id}/details`"
                            class="mx-2">Pokaż
                  </b-button>
                  <b-button :variant="item.removed ? 'success' : 'danger'" @click="changeRemovedStatus(item)">
                    {{ item.removed ? 'Przywróć' : 'Usuń' }}
                  </b-button>
                </template>
              </ecat-table>

            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.createProduct.visible"
        title="Tworzenie nowego produktu"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">

      <div class="form-group">
        <label>Nazwa</label>
        <input v-model="modals.createProduct.product.name" class="form-control" type="text"
               :class="{ 'is-invalid': $v.modals.createProduct.product.name.$error }"/>
        <div v-if="!$v.modals.createProduct.product.name.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Typ</label>
        <vue-multiselect v-model="modals.createProduct.product.type"
                         :options="productTypes ? productTypes : []"
                         :custom-label="value => value.replaceAll('_', ' ').toLowerCase()"
                         placeholder="Wybierz typ"
                         :class="{ 'is-invalid': $v.modals.createProduct.product.type.$error }"
                         :show-labels="false">
        </vue-multiselect>
        <div v-if="!$v.modals.createProduct.product.type.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label for="image">Miniaturka</label>
        <br>

        <input accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" id="image" @change="uploadImage"
               :class="{ 'is-invalid': $v.imageFile.$error }">
        <div v-if="!$v.imageFile.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label>Opis</label>
        <textarea v-model="modals.createProduct.product.description" class="form-control" type="text"
                  :class="{ 'is-invalid': $v.modals.createProduct.product.description.$error }"/>
        <div v-if="!$v.modals.createProduct.product.description.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="text-right">
        <b-button @click="createProduct" variant="success">Utwórz</b-button>
        <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
      </div>
    </b-modal>

  </Layout>
</template>